import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';


@Component({
  selector: 'app-version-popup',
  templateUrl: './version-popup.component.html',
  styleUrl: './version-popup.component.scss'
})
export class VersionPopupComponent implements OnInit {


  isMobile = false
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );


  constructor(
    private breakpointObserver: BreakpointObserver,
    private _snackRef: MatSnackBarRef<VersionPopupComponent>,
  ) { }

  ngOnInit(): void {

    this.isHandset$.subscribe((r) => {
      console.log("IS HANDSET:", r);
      this.isMobile = r;
    });

  }

  refreshVersion() {

    window.location.reload();
  }

  closeSnackBar() {

    this._snackRef.dismiss();


  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import * as fromAuth from '../modules/core/store/reducers/auth.reducers';
import * as fromApp from '../modules/core/store/app.state';
import * as AuthActions from '../modules/core/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AuthService } from './auth.service';
import { environment as env } from '../../environments/environment';
import { SocketJobsNotificationDataType } from '@app/modules/shared/interfaces/socket-jobs-notification-data.type';
import { JobActions, JobStatusType } from '@app/modules/shared/interfaces/background-notification-data.type';


export interface ChannelStream {
  channelName: string;
  responseCount: number,
  closeAfterFirstResponse: boolean,
  data$: Subject<any>;
}



@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private initDone = false;

  private userAuthenticated: boolean = false;
  private endPoint = '';
  private socket: WebSocket | null = null;

  private fp: any | null | undefined = null;
  private browserId = null;

  public socketConnectionStatus$ = new BehaviorSubject<boolean>(false);

  private connectRetries = 0;
  private isSocketConnected = false;

  private channels: ChannelStream[] = [];

  private checkConnectionTimerSubs$!: Subscription;

  private MAX_RETRIES_IN_CONN_CHECK_INTERVAL = 3;
  private CONN_CHECK_INTERVAL = 1000 * 60 * 1;    // 1 Min

  private refreshTokenInProgress: boolean | null = false;

  private timers = timer(5 * 100, this.CONN_CHECK_INTERVAL);

  private currentAccessToken: string | null = null;
  private prevAccessToken: string | null = null;

  private lastConnectionVerificationAt: number | null = null;

  constructor(
    private authService: AuthService,
    private store: Store<fromApp.AppState>) {


    this.store.select('auth').subscribe(async (authState: fromAuth.State) => {
      console.log('[SocketService]: Auth State Changed......:', authState);
      this.refreshTokenInProgress = authState.refreshTokenInProgress;
      this.prevAccessToken = this.currentAccessToken;
      this.currentAccessToken = authState.accessToken;
      this.userAuthenticated = authState.isAuthenticated;

      /**
       * Ensure
       * 0. Init Done
       * 1. Refresh Token is not in progress
       * 2. Current Access Token is not null
       * 3. CUrrent Access Token is not same as previous Access Token
       */
      if (
        this.initDone
        && !this.refreshTokenInProgress
        && this.currentAccessToken
        && (this.prevAccessToken != this.currentAccessToken)
      ) {
        if (this.userAuthenticated) {
          this.endPoint = env.socketOrigin;
          this.endPoint += '?Auth=' + this.currentAccessToken + '&deviceId=' + this.browserId;
          // Reconnect Again........
          this.socketReconnect(false);
        } else {
          console.log('[SocketService]: User is not authenticated. Closing Socket Connection...');
          this.socketDisconnect();
        }
      }
    });

    this.socketConnectionStatus$.subscribe((status: boolean) => {
      this.isSocketConnected = status;
      // console.log('socketConnectionStatus$ : isSocketConnected:', this.isSocketConnected);
    });


  }

  public async init() {
    console.log('[SocketService]: Initializing Socket Service......');

    if (env.stage) {
      this.CONN_CHECK_INTERVAL = 1000 * 60 * 3;  // 3 Min
    }
    if (env.production) {
      this.CONN_CHECK_INTERVAL = 1000 * 60 * 3;  // 3 Min
    }

    this.endPoint = env.socketOrigin;
    // console.log('WS Endpoint:', this.endPoint);

    // We recommend to call `load` at application startup.
    if (!this.fp) {
      this.fp = await FingerprintJS.load();
      // The FingerprintJS agent is ready.
    }
    // Get a visitor identifier when you'd like to.
    const result = await this.fp.get();

    // This is the visitor identifier:
    this.browserId = result.visitorId;
    // console.log('Unique Browser Id:', this.browserId);

    this.initDone = true;
    console.log('[SocketService]: Socket Service Initialized.');
    this.socketConnect();


  }

  private socketConnect() {
    console.log('[SocketService]: socketConnect: Going to perform socketConnect....');

    if (this.initDone) {

      if (this.connectRetries > this.MAX_RETRIES_IN_CONN_CHECK_INTERVAL) {
        console.log(`[SocketService]: socketConnect: Unsuccessful Connect retries is more than ${this.MAX_RETRIES_IN_CONN_CHECK_INTERVAL} times in ${this.CONN_CHECK_INTERVAL / 1000} seconds. Will not try until screen is refreshed or auth token is changed.`);
        return;
      }

      if (!this.userAuthenticated) {
        console.log('[SocketService]: socketConnect:, User is not authenticated. Hence not attempting socket connect..');
        return;
      }

      this.connectRetries++;

      this.endPoint = env.socketOrigin;
      this.endPoint += '?Auth=' + this.currentAccessToken + '&deviceId=' + this.browserId;

      this.socket = new WebSocket(this.endPoint);

      this.socket.onclose = ({ wasClean, code, reason }) => {
        console.log('[SocketService]: Socket connection Closed:', { wasClean, code, reason });
        this.socketConnectionStatus$.next(false);

        if (wasClean && code == 1001) {
          console.log('[SocketService]: Socket Connection May be closed due to idleness, trying to connect again after 0.5s.....');
          setTimeout(() => {
            this.socketConnect();
          }, 500);
        }
      };

      this.socket.onerror = error => {
        console.log('[SocketService]: Socket connection Error:', error);
        // If reached here, this may also caused by expired Auth Token, Lets refresh it and try...
        this.socketReconnect();
      };

      this.socket.onmessage = ({ data }) => {
        console.log('[SocketService]: Socket connection New Message:', data);
        data = JSON.parse(data);
        if (data.channel && Array.isArray(data.channel)) {
          data.channel.forEach((chan: string) => {
            this.broadcastToChannel(chan, data);
          });
        } else if (data.channel) {
          this.broadcastToChannel(data.channel, data);
        } else {
          console.log('[SocketService]: No Channel Specified in data.', data);
        }
      };

      this.socket.onopen = () => {
        console.log('[SocketService]: Socket connection Established...');
        this.socketConnectionStatus$.next(true);
        this.connectRetries = 0;
        // Try sending initial test message...

        const payload = { action: 'welcome', data: { message: 'This is initial test message... from' + this.browserId } };
        this.sendViaSocket(payload);

        this.startConnectionCheckTimer();
      };

    } else {
      console.log('[SocketService]: socketConnect: Init Not Done, hence not doing any thing...');
    }

  }

  private socketReconnect(withNewRefreshToken = true) {
    console.log('[SocketService]: Socket Reconnect.......');
    // Socket is reconnecting ..., lets refresh JWT token.....
    if (withNewRefreshToken) {

      this.socketDisconnect();
      if (!this.refreshTokenInProgress) {
        this.store.dispatch(AuthActions.refreshTokenInProgress({ isAuthenticated: false }));
        this.authService.refreshAccessToken().subscribe(r => {
          console.log('[SocketService]: refreshAccessToken:', r);
          this.store.dispatch(AuthActions.refreshTokenSuccess(r));
          this.socketConnect();
        }, err => {
          console.log("[SocketService]: Some error in refreshing Access Token:", err);
          this.store.dispatch(AuthActions.logout());
        });
      } else {
        console.log('[SocketService]: Refresh Token is already in process..., Connection will be renewed shortly...')
      }

    } else {
      // Using old refresh token...
      this.socketDisconnect();
      this.socketConnect();
    }
  }

  private socketDisconnect(decrementRetryCount = false) {
    console.log('[SocketService]: Going to perform socketDisconnect....');

    this.stopConnectionCheckTimer();
    if (this.socket) {
      this.socket.close();
    }
    this.socketConnectionStatus$.next(false);

    if (decrementRetryCount) {
      this.connectRetries--;
    }
  }

  public verifyConnectionStatus() {
    console.log('[SocketService]: Going to verifyConnectionStatus....');
    let currentTime = new Date().getTime();
    let timeElapsed = currentTime - (this.lastConnectionVerificationAt ?? 0);
    if (timeElapsed < (30 * 1000)) {
      console.log(`[SocketService]: verifyConnectionStatus:, Too frequent verification...., only ${timeElapsed} ms elapsed`);
      return;
    }
    this.lastConnectionVerificationAt = new Date().getTime();

    if (this.socket && (this.socket.readyState == this.socket.OPEN) && this.isSocketConnected) {
      // Do API Call to verify the connection status............
      this.callApiViaSocket('check-connection-status', { time: new Date().getTime() }).subscribe(r => {
        // console.log('[check-connection-status]:', r);
        if (r.status == 599) {
          // console.log('Socket Connection has some problem, it cannot be found in DB, Lets disconnect and connect again..');
          this.socketReconnect();
        }
        if (r.status == 401) {
          // console.log('No Connection found on Backend..., Disconnect Current Connection, Refresh JWT....');
          this.socketDisconnect(true);

          if (!this.refreshTokenInProgress) {
            this.store.dispatch(AuthActions.refreshTokenInProgress({ isAuthenticated: false }));
            this.authService.refreshAccessToken().subscribe(r => {
              // console.log('refreshAccessToken:', r);
              this.store.dispatch(AuthActions.refreshTokenSuccess(r));
            }, err => {
              // console.log("Some error in refreshing Access Token:", err);
              this.store.dispatch(AuthActions.logout());
            });
          } else {
            // console.log('Refresh Token is already in process..., Connection will be renewed shortly...')
          }
        }
      }, err => {
        // console.log('Error in check-connection-status socket api call.', err);
        this.socketReconnect();
      });

    } else {
      // console.log('verifyConnectionStatus : Socket Not Connected.., Going to reconnect...');
      this.socketConnect();
    }

  }

  private startConnectionCheckTimer() {
    this.checkConnectionTimerSubs$ = this.timers.subscribe(t => {
      this.connectRetries = 0;
      // console.log('Resetting Connection Retries Count To 0.');
      this.verifyConnectionStatus();
    });
  }

  private stopConnectionCheckTimer() {
    if (this.checkConnectionTimerSubs$) {
      this.checkConnectionTimerSubs$.unsubscribe();
    }
  }


  private sendViaSocket(payload: any) {
    console.log('[SocketService]: sendViaSocket: payload:', payload);
    if (this.socket && this.socket.OPEN == this.socket.readyState) {
      this.socket.send(JSON.stringify(payload));
    } else {
      console.log('[SocketService]: sendViaSocket: SOCKET is not open yet...',);
      setTimeout(() => {
        this.sendViaSocket(payload);
      }, 250);
    }
  }


  public getSocketIoObject() {
    return this.socket;   // Can be used for requests and listening events
  }

  public callApiViaSocket(url: string, body: any = null) {
    let socketBodyPayload = {
      route: url, body
    };

    // This message will be routed to 'api' based on the 'action'
    // property
    this.sendViaSocket({ action: 'api', data: socketBodyPayload });

    let channel = this.subscribeToChannel(url, true);
    return channel.data$;
  }

  public subscribeToChannel(channelName: string, closeChannelAfterFirstResponse = false) {
    let channel: ChannelStream | null | undefined = this.channels.find(ch => ch.channelName == channelName);
    if (!channel) {
      let bhs = new Subject<any>();
      channel = { channelName, data$: bhs, responseCount: 0, closeAfterFirstResponse: closeChannelAfterFirstResponse };
      this.channels.push(channel);
      console.log('[SocketService]: Channel Created, Can be subscribed now:', channel);
    } else {
      console.log('[SocketService]: Channel Already Available :', channel);
    }

    // Adding test subscriber for the channel which are not to be closed after first response...
    if (!closeChannelAfterFirstResponse) {
      channel.data$.subscribe(r => {
        console.log('[SocketService]: [TEST-SUBSCRIBER-SOCKET-DATA]:', r);
      });
    }

    return channel;
  }

  private broadcastToChannel(channelName: string, data: any) {
    let channel = this.channels.find(ch => ch.channelName == channelName);
    if (!channel) {
      console.log('[SocketService]: Socket - Broadcast channel not available, may be no one is looking... and hence no channel created');
      return;
    }
    channel.data$.next(data);
    console.log(`[SocketService]: Socket - Broadcasted data on channel "${channel.channelName}": `, data);

    channel.responseCount++;

    if (channel.closeAfterFirstResponse && channel.responseCount > 0) {
      // console.log(`Closing channel ${channel.channelName}: `, channel);
      channel.data$.complete();
      this.channels = this.channels.filter(ch => ch.channelName !== channelName);
    }

  }


  localNotify(channelName: string = "jobs-notify", jobId: string = '' + new Date().getTime(), title: string = 'Success', status: JobStatusType = 'finished', progress = 100, actions: JobActions[] = []) {
    const data: SocketJobsNotificationDataType = {
      channel: channelName,
      receiverId: 0,
      senderId: 0,
      payload: {
        queueName: "long-jobs",
        title: title,
        jobId: jobId,
        createdBy: '0',
        status: status,
        progressPercentage: progress,
        actions: actions
      },
    }
    this.broadcastToChannel(data.channel, data);

  }

}
import { CanActivateFn } from '@angular/router';
import * as AuthActions from '../store/actions/auth.actions';
import * as fromAuth from '../store/reducers/auth.reducers';
import * as fromApp from '../store/app.state';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { map, take } from 'rxjs';

export const adminGuard: CanActivateFn = (route, state) => {
  const store = inject(Store<fromApp.AppState>);
  return store.select('auth').pipe(
    take(1),
    map((authState: fromAuth.State) => {
      if (authState.isAuthenticated && (authState?.user?.isAdmin || authState?.user?.isSubAdmin)) {
        return true;
      } else {
        store.dispatch(AuthActions.unAuthorized());
        return false;
      }
    })
  );
};
